<template>
    <div class="sm:flex sm:justify-center ccss--visitor-register">
        <div class="sm:w-1/2 content-container border rounded-sm shadow-sm p-10 checkout-box">
            <div class="text-center">
                <h2 class="font-bold text-2xl text-center mb-5">{{ $t('visitors.title') }}</h2>
            </div>
            <div class="rounded-sm alert-info p-4 mb-4">
                <div class="flex">
                    <div class="flex-shrink-0">
                        <svg class="h-5 w-5 title-main" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clip-rule="evenodd" />
                        </svg>
                    </div>
                    <div class="ml-3">
                        <div class="text-sm leading-5 font-medium">
                            {{ $t('visitors.corona.title') }}
                        </div>
                        <div class="mt-2 text-sm leading-5">
                            <p>{{ $t('visitors.corona.description') }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <Form @submit="onSubmit" :validation-schema="schema" v-slot="{ errors }">
                <div v-if="page == 'signup'">
                    <div class="mb-3">
                        <label for="name" class="block text-xs" :class="{'text-red-500': errors.name}">{{ $t('fields.name') }} *</label>
                        <div class="mt-1">
                            <Field type="text" v-model="form.name" name="name" class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent" :class="{'error': errors.name}" />
                        </div>
                    </div>

                    <div class="mb-3">
                        <label for="email" class="block text-xs" :class="{'text-red-500': errors.email}">{{ $t('fields.email') }} *</label>
                        <div class="mt-1">
                            <Field type="text" v-model="form.email" name="email" class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent" :class="{'error': errors.email}" />
                        </div>
                    </div>

                    <div class="mb-3">
                        <label for="phone_number" class="block text-xs" :class="{'text-red-500': errors.phone_number}">{{ $t('fields.phonenumber') }} *</label>
                        <div class="mt-1">
                            <Field type="text" v-model="form.phone_number" name="phone_number" class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent" :class="{'error': errors.phone_number}" />
                        </div>
                    </div>

                    <div class="mb-3">
                        <label for="table" class="block text-xs" :class="{'text-red-500': errors.table}">{{ $t('fields.tablenumber') }}</label>
                        <div class="mt-1">
                            <Field type="text" name="table" class="py-1 px-2 block w-full shadow-sm border rounded-sm focus:outline-none focus:ring-transparent" :class="{'error': errors.table}" />
                        </div>
                    </div>

                    <div class="mb-3">
                        <div class="flex items-start">
                            <div class="absolute flex items-center h-5">
                                <Field type="checkbox" id="covid_check" name="covid_check" :value="true" class="form-checkbox" :class="{'error': errors.covid_check}" />
                            </div>
                            <div class="pl-7 text-sm leading-5">
                                <label for="covid_check" class="text-base text-main" :class="{'text-error': errors.covid_check}" >{{ $t('visitors.corona.checkbox') }}</label>
                                <p>{{ $t('visitors.corona.checkbox.description') }}</p>
                            </div>
                        </div>
                    </div>

                    <div class="mt-5 relative">
                        <button :disabled="saving" type="submit" class="w-full inline-flex justify-center items-center mt-3 px-6 py-3 btn btn-primary text-base leading-6 font-medium rounded-sm focus:outline-none transition ease-in-out duration-150">
                            {{ $t('button.signup') }}
                            <svg v-if="saving" class="animate-spin ml-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            <svg v-else class="ml-3 -mr-1 h-5 w-5" fill="currentColor" viewBox="0 0 20 20">
                                <path fill-rule="evenodd" d="M10.2929 3.29289C10.6834 2.90237 11.3166 2.90237 11.7071 3.29289L17.7071 9.29289C18.0976 9.68342 18.0976 10.3166 17.7071 10.7071L11.7071 16.7071C11.3166 17.0976 10.6834 17.0976 10.2929 16.7071C9.90237 16.3166 9.90237 15.6834 10.2929 15.2929L14.5858 11L3 11C2.44772 11 2 10.5523 2 10C2 9.44772 2.44772 9 3 9H14.5858L10.2929 4.70711C9.90237 4.31658 9.90237 3.68342 10.2929 3.29289Z" clip-rule="evenodd"/>
                            </svg>
                        </button>
                    </div>
                 </div>

                 <!-- confirmation -->
                <div v-if="page == 'confirmation'">

                    <div class="text-center">
                        <h2 class="font-bold text-2xl text-center">{{ $t('register.thankyou')}}</h2>
                    </div>
                </div>
            </Form>
        </div>
    </div>
</template>

<style>
html {
  /* scroll-behavior: smooth; */
}
</style>

<script>
import axios from 'axios'
import * as _api from '../lib/api'
import moment from 'moment'
import { Field, Form } from 'vee-validate';
import * as yup from "yup";


export default {
    name: 'Reservation',
    props: ['location_slug'],
    components: {
        Field,
        Form,
    },
    data () {
        const schema = yup.object().shape({
            name: yup.string().required().max(255),
            email: yup.string().required().email().max(255),
            phone_number: yup.string().required().max(255),
            table: yup.string(),
            covid_check: yup.bool().required(),
        });

        return {
            page: 'signup',
            saving: false,
            schema,
            timeslot: {},
            timeslots: [],
            form: {
                date:  moment().format('YYYY-MM-DD'),
                timeslot_id: null,
            }
        }
    },
    mounted() {
        // to where to set
        axios.defaults.headers.common['Location'] = this.location_slug
    },

    methods: {

        async onSubmit(data) {
            this.saving = true;

            // store reservation
            const visitor_data = await _api.post('visitors', data)

            if (visitor_data) {
                this.page = 'confirmation'
            }

            this.saving = false;
        }
    },
}
</script>
